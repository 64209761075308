<template>
  <v-main class="main fill-height">
    <v-container
      id="reportContainer"
      pa-0
      fluid
      fill-height
      class="report-wrapper mw-100"
      :class="{ 'mt-0': !bookmarkVisibility }"
    ></v-container>
    <div class="report-bookmark-bar" v-show="bookmarkVisibility">
      <capture-area
        ref="captureComponent"
        :bookmarkList="bookmarkShowcaseState.bookmarks"
        :defaultBookmarkDisplay="defaultPageBookmarkName"
        :loadingBookmarkList="isBookamrksLoading"
        @bookmarkCapture="reportBookmarkCapture($event)"
        @bookmarkItemClicked="applyBookmarkItem($event)"
        @removeBookmarkConfirmed="removeBookmarkItem($event)"
        @hideBookmarkToolbar="hideBookmarkHeader"
      ></capture-area>
    </div>
    <div
      v-if="!bookmarkVisibility && loadedBookmarks"
      class="container-fluid px-0 d-flex justify-content-center"
    >
      <button
        type="button"
        class="btn btn-outline-primary btn-sm show-btn my-3"
        @click="showBookmarkBar"
      >
        <span class="mdi mdi-bookmark-multiple-outline"></span>
        Show Bookmark
      </button>
    </div>
    <v-snackbar
      v-model="snackbar"
      class="snackbar-tooltip"
      :timeout="snackbarTtimeout"
      absolute
      centered
      top
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <span
            class="mdi mdi-window-close close-snackbar-icon"
            title="Close tooltip"
          ></span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>
import axios from "axios";
import * as pbi from "powerbi-client";
import CaptureArea from "./CaptureArea.vue";

export default {
  components: { CaptureArea },
  name: "ViewReport",
  props: ["wid", "rid"],
  data: () => ({
    token: String,
    embedUrl: String,
    type: String,
    bookmarkShowcaseState: {
      bookmarks: [],
      report: null,
      bookmarkCounter: 1,
    },
    loadingBookmark: false,
    defaultPageBookmarkName: "",
    confirmDeleteBookmark: false,
    bookmarkVisibility: false,
    snackbar: false,
    snackbarTtimeout: 3000,
    snackbarMessage: "",
    isBookamrksLoading: false,
    loadedBookmarks: false,
    updatedWorkspaces: [],
    datasetId: "",
    MINUTES_BEFORE_EXPIRATION: 10,
    INTERVAL_TIME: 30000,
    tokenExpiration: "",
    authToken: "",
    powerBi: null,
    reportContainer: "",
  }),

  created() {
    this.startTokenRefreshInterval();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },

  async mounted() {
    this.authToken = await this.$auth.getTokenSilently();
    
    await this.$store.dispatch("getWorkspaces",  this.authToken);
    this.updatedWorkspaces = this.$store.state.workspaces.filter(
      (workspace) => workspace.id == this.wid
    );

    this.updatedWorkspaces.filter((workspace) => {
      workspace.reports.filter((report) => {
        if (report.id == this.rid) {
          this.datasetId = report.datasetId;
        }
      });
    });

    axios
      .get(
        process.env.VUE_APP_ROOT_API +
          `/api/v1/powerbi/reports/embed?workspaceId=${this.wid}&reportId=${this.rid}`,
        {
          headers: { Authorization: `Bearer ${this.authToken}` },
          params: {
            datasetId: this.datasetId,
          },
        }
      )
      .then((response) => {
        this.token = response.data.token;
        this.embedUrl = response.data.embedUrl;
        this.type = response.data.type;
        this.tokenExpiration = response.data.expiration;
        this.embedReport();
      });
  },

  methods: {
    embedReport() {
      this.powerBi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      this.reportContainer = document.getElementById("reportContainer");

      var models = pbi.models;

      // Embed configuration used to describe the what and how to embed.
      // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
      var config = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: this.token,
        embedUrl: this.embedUrl,
        id: this.rid,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
        },
      };

      // Embed the report within the bookmark state
      this.bookmarkShowcaseState.report = this.powerBi.embed(
        this.reportContainer,
        config
      );

      this.bookmarkShowcaseState.report.off("loaded");

      this.bookmarkShowcaseState.report.on("loaded", async function () {
        console.log("Report loaded without loading web componenet");
      });

      this.isBookamrksLoading = true;

      // Fetch bookmarks from localStorage using the reportID
      if (localStorage.getItem(this.rid) !== null) {
        let savedBookmarks = JSON.parse(localStorage.getItem(this.rid));
        let existingBookmarks = this.bookmarkShowcaseState.bookmarks;
        this.bookmarkShowcaseState.bookmarks = [
          ...existingBookmarks,
          ...savedBookmarks,
        ];
        // Remove loader after bookmarks fetched from local storage
        this.isBookamrksLoading = false;
      } else {
        this.isBookamrksLoading = false;
      }

      this.bookmarkShowcaseState.report.on("pageChanged", (event) => {
        this.loadedBookmarks = true;
        this.$refs.captureComponent.closeDropdown();
        const reportPageName = event.detail.newPage.displayName;
        this.defaultPageBookmarkName = "Default Settings: " + reportPageName;

        this.reportBookmarkCapture({
          modalId: "",
          modalOpen: false,
          capturedViewname: this.defaultPageBookmarkName,
        });
      });

      this.bookmarkShowcaseState.report.on("error", function (event) {
        console.log("Report loading error", event.detail);
      });

      this.bookmarkShowcaseState.report.off("visualClicked");
      this.bookmarkShowcaseState.report.on("visualClicked", function (event) {
        console.log(event.detail, "visual clicked");
      });
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    applyBookmarkItem(bookmarkItem) {
      // Set bookmark active by accessing child component method
      this.$refs.captureComponent.setActiveBookmark(bookmarkItem.name);
      let currentBookmark = this.bookmarkShowcaseState.bookmarks.find(
        (bookmark) => {
          return bookmark.name === bookmarkItem.name;
        }
      );

      // Apply the bookmark state
      this.bookmarkShowcaseState.report.bookmarksManager.applyState(
        currentBookmark.state
      );
      this.$refs.captureComponent.closeDropdown();
    },

    async reportBookmarkCapture(payload) {
      const isExisting = this.bookmarkShowcaseState.bookmarks.some(
        (element) => {
          if (element.displayName === payload.capturedViewname) {
            return true;
          }
          return false;
        }
      );

      if (
        this.bookmarkShowcaseState.report &&
        this.bookmarkShowcaseState.report.bookmarksManager &&
        !isExisting
      ) {
        this.loadingBookmark = true;

        // Capture the report's current state with personalized visuals
        await this.bookmarkShowcaseState.report.bookmarksManager
          .capture({ allPages: true, personalizeVisuals: true })
          .then((result) => {
            const bookmarkCaptured = result;

            if (bookmarkCaptured && bookmarkCaptured.state) {
              // Build bookmark element
              let bookmark = {
                name:
                  bookmarkCaptured.name +
                  "_" +
                  this.bookmarkShowcaseState.bookmarkCounter,
                displayName: payload.capturedViewname,
                state: bookmarkCaptured.state,
              };

              this.bookmarkShowcaseState["bookmarks"].unshift({
                ...bookmark,
              });

              this.bookmarkShowcaseState.bookmarkCounter++;

              this.$refs.captureComponent.addActiveToLatest(
                bookmark.name,
                payload.modalOpen
              );

              if (payload.modalOpen) {
                this.addBookmarksToLocalstorage();
                payload.modalId.modal("hide");
                this.snackbar = true;
                this.snackbarMessage = "Bookmark saved!";
              }
            }
          })
          .catch((error) => {
            console.error("Bookmarks capture error=", error);
            this.snackbar = true;
            this.snackbarMessage = "Failed to save Bookmark..";
          })
          .finally(() => {
            this.loadingBookmark = false;
          });
      }
    },

    addBookmarksToLocalstorage() {
      // Remove default state
      let reportBookmarks = this.bookmarkShowcaseState.bookmarks.filter(
        (bookmark) => {
          return !bookmark.displayName.includes("Default Settings");
        }
      );
      localStorage.setItem(this.rid, JSON.stringify(reportBookmarks));
    },

    async removeBookmarkItem(index) {
      await this.bookmarkShowcaseState.bookmarks.splice(index, 1);
      this.addBookmarksToLocalstorage();
    },

    showBookmarkBar() {
      this.bookmarkVisibility = true;
    },
    
    hideBookmarkHeader() {
      this.bookmarkVisibility = false;
    },

    async getReportAccessData(groupId, reportId, token) {
      await axios
        .get(
          process.env.VUE_APP_ROOT_API +
            `/api/v1/powerbi/reports/embed?workspaceId=${groupId}&reportId=${reportId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              datasetId: this.datasetId,
            },
          }
        )
        .then((response) => {
          this.token = response.data.token;
          this.embedUrl = response.data.embedUrl;
          this.type = response.data.type;
          this.tokenExpiration = response.data.expiration;
        });
    },

    startTokenRefreshInterval() {
      setInterval(this.checkTokenAndUpdate, this.INTERVAL_TIME);
    },

    checkTokenAndUpdate() {
      const currentTime = Date.now();
      const expiration = Date.parse(this.tokenExpiration);
      const timeUntilExpiration = expiration - currentTime;
      const timeToUpdate = this.MINUTES_BEFORE_EXPIRATION * 60 * 1000;

      if (timeUntilExpiration <= timeToUpdate) {
        this.updateToken();
      }
    },

    async updateToken() {
      // Generate a new embed token or refresh the user Azure AD access token
      await this.getReportAccessData(this.wid, this.rid, this.authToken);

      let report = this.powerBi.get(this.reportContainer);

      // Set the new access token
      await report.setAccessToken(this.token);
    },

    handleVisibilityChange() {
      if (!document.hidden) {
        this.checkTokenAndUpdate();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  background: #fff;
}
.show-btn {
  font-size: 0.8rem;
  color: #1461f8;
  &:hover {
    color: #1461f8;
    background: rgba(20, 97, 248, 0.25);
  }
}
.snackbar-tooltip {
  z-index: 100;
}
.close-snackbar-icon {
  color: #fff;
}
</style>
