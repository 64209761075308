<template>
    <v-container grid-list-md text-center>
            <h1>This page does not exist.</h1>
    </v-container>
</template>

<script>
  export default {
    name: "NotFound",
  }
</script>