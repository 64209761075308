<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<style>
@font-face {
    font-family: "Arial Black";
}

</style>

<script>

export default {
    name: 'App',
};
</script>
