import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  workspaces: [],
  workspacesLoaded: false,
  users: [],
  usersLoaded: false,
};

const mutations = {
  SET_LOADING_WORKSPACE(state, status) {
    state.workspacesLoaded = status;
  },
  SET_WORKSPACES(state, workspaces) {
    state.workspaces = workspaces;
  },
  SET_LOADING_USERS(state, status) {
    state.usersLoaded = status;
  },
  SET_USERS(state, users) {
    state.users = state.users.concat(users);
  },
  SORT_USERS(state) {
    state.users = state.users.sort(function (a, b) {
      const aFullname = a.fullName.trim().toLowerCase();
      const bFullname = b.fullName.trim().toLowerCase();
      if (aFullname < bFullname) {
        return -1;
      }
      if (aFullname > bFullname) {
        return 1;
      }
      return 0;
    });
  },
};

const actions = {
  async getWorkspaces({ commit }, token) {
    await axios
      .get(process.env.VUE_APP_ROOT_API + "/api/v1/powerbi/get", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        commit("SET_WORKSPACES", result.data);
        commit("SET_LOADING_WORKSPACE", true);
      });
  },
  async getUsers({ commit }, token) {
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

    for (let i = 0; i < alphabet.length; i += 2) {
      let page = 0;
      let amount = 100;
      let amountRetrieved = amount;
      const firstLetter = alphabet[i];
      const secondLetter = alphabet[i + 1] ? alphabet[i + 1] : "";

      const searchQuery = `name:${firstLetter}* OR name:${secondLetter}*`;

      while (amount == amountRetrieved) {
        try {
          const result = await axios.get(
            process.env.VUE_APP_ROOT_API + "/api/v1/auth/users",
            {
              headers: { Authorization: `Bearer ${token}` },
              params: {
                page: page,
                amount: amount,
                include_totals: true,
                q: searchQuery,
                search_engine: "v3",
              },
            }
          );

          const users = result.data;
          commit("SET_USERS", users);
          amountRetrieved = users.length;
          page++;
        } catch (error) {
          console.error("Error fetching users:", error);
          break;
        }
      }
    }
    commit("SORT_USERS");
    commit("SET_LOADING_USERS", true);
  },
};

const getters = {
  workspaces: (state) => state.workspaces,
  users: (state) => state.users,
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
