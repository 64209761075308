<template>
  <v-main class="grey lighten-5">
    <router-view />
  </v-main>
</template>

<script>

export default {
    name: "Base",
  }
</script>
