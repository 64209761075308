<template>
  <div class="capture-area-wrapper">
    <div class="capture-area">
      <!-- Capture Buttons -->
      <div class="fixed-bottom interactive-buttons px-3 py-2">
        <div class="bookmarks-dropdown position-relative" :class="{ show: showBookmarkList }">
          <button
            class="btn btn-action mr-2"
            type="button"
            id="display-btn"
            aria-haspopup="true"
            :aria-expanded="showBookmarkList"
            @click="showBookmarksDropdown"
          >
            <span class="mdi mdi-format-list-bulleted-square"></span>
            Saved Views
          </button>
          <ul
            class="dropdown-menu checkbox-menu allow-focus bookmarks-list-menu px-2"
            :class="{ show: showBookmarkList }"
            id="bookmarks-list"
            aria-labelledby="display-btn"
          >
            <label class="close-dropdown fixed-top">
              <button
                id="close-btn"
                class="dropdown-close-btn"
                aria-label="Close bookmarks dropdown"
              >
                <span
                  class="mdi mdi-window-close close-btn-icon"
                  @click="closeDropdown"
                ></span>
              </button>
            </label>
            <div class="bookmark-dropdown-loader" v-if="loadingBookmarkList">
              Loading...
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
            <p
              v-if="!bookmarkList.length && !loadingBookmarkList"
              class="empty-message text-center"
            >
              There is no bookmarks added
            </p>
            <div
              v-for="(bookmark, index) in bookmarkList"
              :key="index"
              class="showcase-checkbox-container pr-2 mt-2 d-flex align-center"
            >
              <label
                class="showcase-checkbox-label m-0 text-truncate"
                :class="{ activebookmark: bookmark.name === activeBookmarkId }"
              >
                <input
                  type="checkbox"
                  name="bookmark"
                  :id="bookmark.name"
                  @click="onBookmarkClicked(bookmark)"
                />
                <span class="dropdown-bookmark-title ml-2" :title="bookmark.displayName">{{
                  bookmark.displayName
                }}</span>
              </label>
              <span
                v-if="!bookmark.displayName.includes('Default Settings')"
                title="Delete Bookmark"
                class="mdi mdi-close-circle bookmark-close-icon ml-auto"
                @click.stop="removeBookmark(index)"
              ></span>
            </div>
          </ul>
        </div>
        <div class="capture-bookmark">
          <button
            class="btn btn-action"
            type="button"
            id="capture-btn"
            data-toggle="modal"
            data-target="#modal-action"
            aria-haspopup="true"
          >
            <span class="mdi mdi-bookmark-plus-outline"></span>
            Capture View
          </button>
        </div>
        <div class="hide-bookmark ml-auto">
          <span
            class="mdi mdi-close-box hide-bookmark-icon"
            title="Close bookmark"
            @click="hideBookmark"
          ></span>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-action"
      role="dialog"
      aria-labelledby="capture-btn"
      aria-hidden="true"
    >
      <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center">
          <div class="modal-content">
            <div class="d-flex flex-row-reverse">
              <button
                type="button"
                id="close-modal-btn"
                class="bookmark-modal-close"
                data-dismiss="modal"
                aria-label="Close dialog"
              >
                <span class="mdi mdi-window-close close-btn-icon m-2"></span>
              </button>
            </div>
            <div class="modal-header p-0 m-0">
              <h4
                class="capture-modal-title w-100 text-center"
                id="my-modal-label"
              >
                Save to 'Saved Views'
              </h4>
            </div>
            <div class="modal-body" role="tablist">
              <div id="save-view-div">
                <form novalidate>
                  <label class="view-label" for="viewname"
                    >Enter a name for this view:</label
                  >
                  <input
                    type="text"
                    v-model="bookmarkName"
                    class="modal-text bookmark-input mt-3 pl-2 w-100"
                    id="viewname"
                    placeholder="Please type your bookmark name"
                    @focus="$event.target.select()"
                    required
                  />
                  <div v-if="invalid || existingName || existingDefaultName" class="text-danger mt-2">
                    {{ validationMessages }}
                  </div>
                  <div class="save-btn-div mt-4 text-center">
                    <button
                      class="bookmark-save-btn"
                      type="button"
                      @click="onBookmarkCaptureClicked"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Confirm delete dialogue -->
    <v-dialog
      v-model="confirmDeleteBookmark"
      content-class="delete-dialogue"
      @keydown.esc="cancelRemoveBookmark"
    >
      <v-card>
        <v-toolbar dark color="grey lighten-3" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            Confirm
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to delete this bookmark?</v-card-text
        >
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click.native="cancelRemoveBookmark"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click.native="confirmRemoveBookmark"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "CaptureArea",
  props: {
    bookmarkList: Array,
    defaultBookmarkDisplay: String,
    loadingBookmarkList: Boolean,
  },
  data() {
    return {
      bookmarkName: "",
      invalid: false,
      existingName: false,
      existingDefaultName: false,
      activeBookmarkId: 0,
      showBookmarkList: false,
      confirmDeleteBookmark: false,
      deleteBookmarkIndex: -1,
      validationMessages: ''
    };
  },
  mounted() {
    // Apply focus on the close button when it is opened
    $("#modal-action").on("shown.bs.modal", this.onModalOpen);
    $("#modal-action").on("hidden.bs.modal", this.onModalClose);

    // add a click listener to close the dropdown on outside click
    window.addEventListener("click", this.closeDropdownOutside);
  },
  methods: {
    onBookmarkCaptureClicked() {
      let capturedViewname = this.bookmarkName.trim();
      let isExisting = this.bookmarkList.some(element => {
        if (element.displayName === capturedViewname) {
          return true;
        }
        return false;
      });
      let lowerDefaultString = ('Default Settings').toLowerCase()
      let isDefaultExisting = capturedViewname.toLowerCase().includes(lowerDefaultString)
      if(!capturedViewname) {
        this.invalid = true;
        this.validationMessages = 'Please provide a valid name.'
      } else if(isExisting) {
        this.invalid = true
        this.validationMessages = 'The name you have provided is already exists in bookmark list.'
      }
      else if(isDefaultExisting) {
        this.invalid = true;
        this.validationMessages = '"Default Settings" keyword is reserved for default page views. Please check with a different name.'
      } else {
        this.invalid = false;
        this.existingDefaultName = false;
        this.existingName = false
        let modalId = $("#modal-action");
        let emittedValues = {
          modalId,
          modalOpen: true,
          capturedViewname,
        };
        this.$emit("bookmarkCapture", emittedValues);
      }
    },
    addActiveToLatest(activeId, isDefaultView) {
      this.setActiveBookmark(activeId);
      this.bookmarkName = "";
      if (isDefaultView) {
        this.showBookmarksDropdown();
      }
    },
    setActiveBookmark(bookmarkId) {
      let selectedBookmark = this.bookmarkList.find((bookmark) => {
        return bookmark.name === bookmarkId;
      });
      this.activeBookmarkId = selectedBookmark.name;
      $("input:checkbox").prop("checked", false);
      $("#" + selectedBookmark.name).prop("checked", true);
    },
    showBookmarksDropdown() {
      this.showBookmarkList = true;
    },
    closeDropdown() {
      this.showBookmarkList = false;
    },
    onBookmarkClicked(item) {
      this.$emit("bookmarkItemClicked", item);
    },
    removeBookmark(index) {
      this.deleteBookmarkIndex = index;
      this.confirmDeleteBookmark = true;
      this.showBookmarksDropdown();
    },
    confirmRemoveBookmark() {
      this.$emit("removeBookmarkConfirmed", this.deleteBookmarkIndex);
      this.confirmDeleteBookmark = false;
    },
    cancelRemoveBookmark(e) {
      e.stopPropagation();
      this.confirmDeleteBookmark = false;
    },
    onModalOpen() {
      this.closeDropdown();
      $("input:text").focus();
    },
    onModalClose() {
      this.bookmarkName = ''
      this.invalid = false
    },
    closeDropdownOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showBookmarkList = false;
      }
    },
    hideBookmark() {
      this.$emit("hideBookmarkToolbar");
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropdownOutside);
  },
};
</script>
<style lang="scss" scoped>
.interactive-buttons {
  background: #fff;
  display: flex;
  z-index: 99 !important;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.btn[aria-expanded="true"] {
  background: rgba(20, 97, 248, 0.15);
  color: #1461f8;
}
.btn-action {
  align-items: flex-end;
  background: transparent;
  border: 1px solid #1461f8;
  border-radius: 4px;
  color: #1461f8;
  font: 0.8rem "Segoe UI SemiBold", segoe_ui_semibold, Tahoma, Arial;
  outline: none;
  text-align: center;
  &:hover {
    background: rgba(20, 97, 248, 0.25);
  }
  /* For keyboard focus */
  &:focus-visible {
    box-shadow: 0 0 0 1.5px rgba(0, 123, 255, 0.5);
  }
  /* For mouse focus */
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}
.hide-bookmark {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: #343a40;
  &-icon {
    cursor: pointer;
    line-height: 1;
    &:hover {
      opacity: 0.7;
    }
  }
}
.showcase-checkbox {
  &-container {
    font-weight: normal;
    height: auto;
    margin: 0;
    color: #323130;
    font: 400 14px/20px "Segoe UI", segoe_ui_regular, Tahoma, Arial;
  }
  &-label {
    position: relative;
    cursor: pointer;
    & input {
      opacity: 0;
      position: absolute;
      top: 5px;
    }
  }
}
.close-dropdown {
  cursor: default;
  display: flex;
  height: 28px;
  margin: 1.5px 1.5px 0 0;
}
.empty-message {
  font: 500 12px "Segoe UI", segoe_ui_semibold, Tahoma, Arial;
  padding: 16px;
  margin: 0;
}
.bookmark-close-icon {
  cursor: pointer;
  &:hover {
    color: rgb(229 11 11);
  }
}
.dropdown-close-btn {
  background: transparent;
  border: 0;
  margin-left: auto;
  outline: none;
  padding: 4px;
}
.close-btn-icon:hover {
  color: #1461f8;
}
.bookmark-dropdown-loader {
  position: absolute;
  left: 65px;
  top: 15%;
}
.bookmarks-list-menu {
  background: #fff;
  box-shadow: 0 1.2px 3.6px rgba(0, 0, 0, 0.108),
    0 6.4px 14.4px rgba(90, 61, 61, 0.132);
  height: auto;
  /* Show scroll for small screen */
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: -15px;
  width: 228px;
  padding-top: 30px;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a0aeb2;
    border-radius: 10px;
    height: 30px;
  }
}
.vertical-alignment-helper {
  display: table;
  height: 100%;
  pointer-events: none;
  width: 100%;
}

.vertical-align-center {
  display: table-cell;
  pointer-events: none;
  vertical-align: middle;
}

.modal-content {
  box-shadow: 0 4.8px 14.4px rgba(0, 0, 0, 0.18),
    0 25.6px 57.6px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  height: 303px;
  margin: 0 auto;
  max-width: inherit;
  pointer-events: all;
  width: inherit;
}

.modal-body {
  color: #201f1e;
  font: 400 14px "Segoe UI", segoe_ui_regular, Tahoma, Arial;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:hover {
  box-shadow: none;
  color: #1461f8;
  outline: 0;
  outline-offset: -2px;
  text-decoration: none;
}

.bookmark-modal-close {
  height: 40px;
  width: 40px;
}
.bookmark-save-btn {
  background: transparent;
  border-radius: 4px;
  border: 1px solid #1461f8;
  color: #1461f8;
  font-weight: 600;
  margin: 5px 20px 0 5px;
  outline: none;
  padding: 6px 16px;

  &:hover {
    background: #1461f8;
    color: #fff;
  }
  &:active {
    background: rgba(20, 97, 248, 0.15);
    color: #1461f8;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2.5px rgba(0, 123, 255, 0.5);
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}
.bookmark-input {
  height: 32px;
  outline: none;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  &:focus-visible {
    box-shadow: 0 0 0 2.5px rgba(0, 123, 255, 0.5);
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
  &::placeholder {
    font: 400 14px/20px "Segoe UI", segoe_ui_regular, Tahoma, Arial;
  }
}
.view-label {
  margin-bottom: 0;
  font-size: 14px;
}
.modal-header {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.capture-modal-title {
  font: 16px "Segoe UI SemiBold", segoe_ui_semibold, Tahoma, Arial;
}
.activebookmark {
  color: #1461f8;
}
</style>

<!-- Styles for scoped outside of this component -->
<style>
.v-dialog__content {
  z-index: 999 !important;
}
.v-dialog.delete-dialogue {
  max-width: 400px;
}
</style>
